// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "bootstrap"
import "custom_js/home"

// Rails.start()
ActiveStorage.start()

require.context("../images", true);
import "../stylesheets/application"

// var jQuery = require('jquery')
// // include jQuery in global and window scope (so you can access it globally)
// // in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
// global.$ = global.jQuery = jQuery;
// window.$ = window.jQuery = jQuery;
